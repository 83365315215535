import { ITrackNew, Main } from 'wikr-core-analytics';

type CustomDataOptions = { event_action?: string; event_label?: string | string[] | object };

export const customTrackDecorator = (
    eventType: string,
    eventAction?: string,
    eventLabel?: string | string[] | object
) => {
    const trackParams: ITrackNew = {
        // @ts-ignore because we don't use enums from lib
        eventData: eventType,
        // @ts-ignore because we don't use enums from lib
        actionData: eventAction,
    };

    if (eventLabel !== undefined) {
        trackParams.data = { event_label: eventLabel };
    }

    Main.trackNew(trackParams);
};

export const trackClick = (eventId: string, options?: CustomDataOptions) => {
    customTrackDecorator(`${eventId}__click`, options?.event_action, options?.event_label);
};

export const trackEvent = (eventId: string, options?: CustomDataOptions) => {
    customTrackDecorator(eventId, options?.event_action, options?.event_label);
};

export const trackScreenLoad = (eventId: string, options?: CustomDataOptions) => {
    customTrackDecorator(`${eventId}__screen__load`, options?.event_action, options?.event_label);
};
