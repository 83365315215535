import { createSelector } from 'reselect';

import { UpsellProduct } from 'redux/sagas/user/interfaces';

import { ModalOpenStatuses, PaymentStore } from 'interfaces/Store/paymentStore';
import { RootState } from '../rootReducer';

const selectPayment = (state: RootState) => <PaymentStore>state.payment;
const selectCurrency = createSelector(selectPayment, (payment) => payment.currency);
const selectUpsellProduct = createSelector(selectPayment, (payment) => payment.upsellProduct);
const selectValidPaymentInfo = createSelector(selectPayment, (payment) => payment.validPaymentInfo);
const selectIsLoading = createSelector(selectPayment, (payment) => payment.isLoading);
const selectIsLoadingBankCard = createSelector(selectPayment, (payment) => payment.isLoadingBankCard);
const selectIsLoadingPayPal = createSelector(selectPayment, (payment) => payment.isLoadingPayPal);
const selectCurrentProduct = createSelector(selectPayment, (payment) => payment.currentProduct);
const selectUpgradedProduct = createSelector(selectPayment, (payment) => payment.upgradedProduct);
const selectSolidInited = createSelector(selectPayment, (payment) => payment.solidInited);
const selectSandbox = createSelector(selectPayment, (payment) => payment.sandbox);
const selectValidatePaymentData = createSelector(selectPayment, (payment) => payment.validatePaymentData);
const selectInitPaymentData = createSelector(selectPayment, (payment) => payment.initPaymentData);
const selectPurchaseWithoutValue = createSelector(selectPayment, (payment) => payment.purchaseWithoutValue);
const selectIntroductoryDiscount = createSelector(selectPayment, (payment) => payment.introductoryDiscount);
const selectSubscriptionId = createSelector(selectPayment, (payment) => payment.subscriptionId);
const selectPaymentMethod = createSelector(selectPayment, (payment) => payment.paymentMethod);
const selectIsTimerEnd = createSelector(selectPayment, (payment) => payment.isTimerEnd);
const selectMerchantData = createSelector(selectPayment, (payment) => payment.merchantData);
const selectIsApplePayAvailable = createSelector(selectPayment, (payment) => payment.isApplePayAvailable);

const selectIsGooglePayAvailable = createSelector(selectPayment, (payment) => payment.isGooglePayAvailable);

const selectExclusiveOfferHasBeenOpened = createSelector(
    selectPayment,
    (payment) => payment.exclusiveOfferHasBeenOpened
);
const selectIsCustomPaymentButton = createSelector(selectPayment, (payment) => payment.isCustomPaymentButton);
const selectIsHideCustomPaymentButton = createSelector(selectPayment, (payment) => payment.isHideCustomPaymentButton);

const selectPaymentModals = createSelector(selectPayment, (payment) => payment?.modals);
const selectPaymentModalOpenStatus = createSelector(
    selectPaymentModals,
    (modals: ModalOpenStatuses) => modals?.paymentModal
);
const selectExclusiveModalOpenStatus = createSelector(
    selectPaymentModals,
    (modals: ModalOpenStatuses) => modals?.exclusiveOfferModal
);
const selectCheckoutModalOpenStatus = createSelector(
    selectPaymentModals,
    (modals: ModalOpenStatuses) => modals?.checkoutModal
);
const selectOneClickPaymentPrice = createSelector(
    selectUpsellProduct,
    (upsellProduct: UpsellProduct) => upsellProduct.price
);
const selectUpsellId = createSelector(selectUpsellProduct, (upsellProduct: UpsellProduct) => upsellProduct?.id);
const selectUpsellLtv = createSelector(selectUpsellProduct, (upsellProduct: UpsellProduct) => upsellProduct?.ltv);
const selectUpsellPaymentType = createSelector(
    selectUpsellProduct,
    (upsellProduct: UpsellProduct) => upsellProduct?.payment_type
);
const selectUpsellProductCode = createSelector(
    selectUpsellProduct,
    (upsellProduct: UpsellProduct) => upsellProduct?.product_code
);
const selectUpsellProductPeriod = createSelector(
    selectUpsellProduct,
    (upsellProduct: UpsellProduct) => upsellProduct?.period
);
const selectUpsellProductName = createSelector(
    selectUpsellProduct,
    (upsellProduct: UpsellProduct) => upsellProduct?.name
);
const selectIsPaymentSuccess = createSelector(selectPayment, (payment) => payment.isPaymentSuccess);

export {
    selectPayment,
    selectCurrency,
    selectOneClickPaymentPrice,
    selectUpsellId,
    selectValidPaymentInfo,
    selectIsLoading,
    selectCurrentProduct,
    selectSandbox,
    selectSolidInited,
    selectInitPaymentData,
    selectValidatePaymentData,
    selectUpsellLtv,
    selectPurchaseWithoutValue,
    selectIntroductoryDiscount,
    selectUpsellPaymentType,
    selectUpsellProductCode,
    selectSubscriptionId,
    selectPaymentMethod,
    selectUpgradedProduct,
    selectPaymentModalOpenStatus,
    selectExclusiveModalOpenStatus,
    selectCheckoutModalOpenStatus,
    selectExclusiveOfferHasBeenOpened,
    selectIsCustomPaymentButton,
    selectUpsellProductPeriod,
    selectUpsellProductName,
    selectIsTimerEnd,
    selectMerchantData,
    selectIsHideCustomPaymentButton,
    selectIsApplePayAvailable,
    selectUpsellProduct,
    selectIsPaymentSuccess,
    selectIsGooglePayAvailable,
    selectIsLoadingBankCard,
    selectIsLoadingPayPal,
};
