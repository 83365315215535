import { userDataAccessor } from 'redux/User/accessors';
import { clearCompleteOBs, setCompleteOBs, setCurrentBranchName, setTestaniaLoginStatus } from 'redux/Testania/actions';
import { setMeasure } from 'redux/Onboadring/actions';

import { COUNTRIES_IMPERIAL_SYSTEM, COUNTRIES_LIST } from 'constants/general/countriesData';
import { URL_PARAMS_FOR_SKIP_THANK_YOU_PAGE } from './constants';

export const getBranchNameFromLocalStorage = () => {
    const config = localStorage.getItem('config');

    return JSON.parse(config)?.branch_name;
};

export const getNameFromUrl = (name, defaultValue) => {
    const params = new URLSearchParams(window.location.search);

    return params.get(name) || defaultValue;
};

export const setCurrentBranchToStore = async (branch, dispatch) => {
    dispatch(setCurrentBranchName(branch));
};

export const setCompleteOBsToStore = async (branch, dispatch) => {
    dispatch(setCompleteOBs(branch));
};

export const setTestaniaLoginStatusToStore = async (status, dispatch) => {
    dispatch(setTestaniaLoginStatus(status));
};

export const getIsLogin = async (testaniaLoginStatus, token) => {
    return testaniaLoginStatus === 'true' || !!token || false;
};

export const getUserStatus = async () => {
    const { name, surname, productList } = userDataAccessor();

    return {
        isPaid: Boolean(productList?.main_plan || productList?.app_full_access),
        isPasswordSet: Boolean(name || surname),
        name,
        surname,
    };
};

export const clearCompleteOBsToStore = async (dispatch) => {
    dispatch(clearCompleteOBs());
};

export const setInitMeasure = (country, dispatch) => {
    dispatch(setMeasure(COUNTRIES_IMPERIAL_SYSTEM.includes(country) ? 'IMPERIAL' : 'METRIC'));
};

export const validateCountry = (country) => {
    const countryCodeList = COUNTRIES_LIST.map((el) => el.code);

    return Boolean(typeof country === 'string' && countryCodeList.includes(country));
};

export const shouldSkipThankYouPage = (testaniaName, urlParams) => {
    const isTest = testaniaName?.split('__').pop() === 'TestB';
    const branchName = urlParams?.['branch-name'];
    const testName = urlParams?.['test-name'];

    return Boolean(
        isTest &&
            branchName === URL_PARAMS_FOR_SKIP_THANK_YOU_PAGE.branchName &&
            testName === URL_PARAMS_FOR_SKIP_THANK_YOU_PAGE.testName
    );
};
