/* eslint-disable max-lines */
import * as actionTypes from './actionTypes';

import { PAYMENT_DEFAULT_CURRENCY } from 'constants/payment/payments';
import { DEFAULT_MODAL_OPEN_STATUS_VALUES } from 'constants/payment/modals';

import { ActionType } from 'types/commonInterfaces';

import { PaymentStore } from 'interfaces/Store/paymentStore';

const initialState: PaymentStore = {
    isLoading: false,
    solidInited: false,
    paymentValidate: {},
    applePay: '',
    sandbox: false,
    initPaymentData: null,
    currency: PAYMENT_DEFAULT_CURRENCY,
    currentProduct: null,
    upgradedProduct: null,
    upsellProduct: null,
    productCode: null,
    // @ts-ignore
    validPaymentInfo: {},
    validatePaymentData: null,
    isErrorModalOpened: false,
    purchaseWithoutValue: false,
    introductoryDiscount: 0,
    subscriptionId: null,
    currentCancelSubscriptionId: null,
    modals: DEFAULT_MODAL_OPEN_STATUS_VALUES,
    exclusiveOfferHasBeenOpened: false,
    paymentMethod: null,
    isTimerEnd: false,
    merchantData: null,
    isCustomPaymentButton: false,
    isHideCustomPaymentButton: false,
    isApplePayAvailable: false,
    isGooglePayAvailable: false,
    paymentFailToRetenoObj: null,
    isLoadingBankCard: false,
    isLoadingPayPal: false,
    isPaymentSuccess: false,
    invoiceInfoReceived: false,
    prevPaymentAttempt: null,
};

const PaymentReducer = (state = initialState, action: ActionType<keyof PaymentStore>) => {
    switch (action.type) {
        case actionTypes.SET_CURRENT_PRODUCT: {
            // TODO: check usage
            // @ts-ignore
            localStorage.setItem('paymentType', action.payload.payment_type);

            return {
                ...state,
                currentProduct: action.payload,
            };
        }

        case actionTypes.INIT_SOLID_PAYMENT:
            return {
                ...state,
                solidInited: action.payload,
            };

        case actionTypes.SET_LOADING_STATUS:
            return {
                ...state,
                isLoading: action.payload,
            };

        case actionTypes.SET_PAYMENT_DATA:
            return {
                ...state,
                initPaymentData: action.payload,
            };

        case actionTypes.VALIDATE_PAYMENT_DATA:
            return {
                ...state,
                validatePaymentData: action.payload,
            };

        case actionTypes.INIT_PAYMENT:
            return {
                ...state,
            };

        case actionTypes.SET_MERCHANT_DATA:
            return {
                ...state,
                merchantData: action.payload,
            };

        case actionTypes.SET_PAYMENT_CURRENCY: {
            return {
                ...state,
                currency: action.payload,
            };
        }

        case actionTypes.VALIDATE_PAYMENT: {
            return {
                ...state,
                validPaymentInfo: action.payload,
                isLoading: true,
            };
        }

        case actionTypes.SET_UPSELL_PRODUCT_ID:
            return {
                ...state,
                upsellProduct: {
                    ...state,
                    id: action.payload,
                },
            };

        case actionTypes.SET_UPSELL_PRODUCT:
            return {
                ...state,
                upsellProduct: action.payload,
            };

        case actionTypes.CHANGE_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.payload,
            };

        case actionTypes.SET_PURCHASE_WITHOUT_VALUE:
            return {
                ...state,
                purchaseWithoutValue: action.payload,
            };

        case actionTypes.SET_SUBSCRIPTION_ID:
            return {
                ...state,
                subscriptionId: action.payload,
            };

        case actionTypes.SET_INTRODUCTORY_DISCOUNT:
            return {
                ...state,
                introductoryDiscount: action.payload,
            };

        case actionTypes.SET_SANDBOX:
            return {
                ...state,
                sandbox: action.payload,
            };

        case actionTypes.SET_UPGRADED_PRODUCT:
            return {
                ...state,
                upgradedProduct: action.payload,
            };

        case actionTypes.SET_CURRENT_CANCEL_SUBSCRIPTION_ID:
            return {
                ...state,
                currentCancelSubscriptionId: action.payload,
            };

        case actionTypes.SET_OPEN_MODAL:
            return {
                ...state,
                modals: action.payload,
            };

        case actionTypes.CLOSE_MODALS:
            return {
                ...state,
                modals: DEFAULT_MODAL_OPEN_STATUS_VALUES,
            };

        case actionTypes.SET_EXCLUSIVE_OFFER_HAS_BEEN_OPENED:
            return {
                ...state,
                exclusiveOfferHasBeenOpened: action.payload,
            };

        case actionTypes.SET_EXCLUSIVE_OFFER_MODAL_STATUS_CLOSE:
            return {
                ...state,
                modals: { ...state.modals, exclusiveOfferModal: false },
            };

        case actionTypes.SET_IS_PAYMENT_SCREEN:
            return {
                ...state,
                isPaymentScreen: action.payload,
            };

        case actionTypes.SET_CUSTOM_PAYMENT_BUTTON:
            return {
                ...state,
                isCustomPaymentButton: action.payload,
            };

        case actionTypes.SET_HIDE_CUSTOM_PAYMENT_BUTTON:
            return {
                ...state,
                isHideCustomPaymentButton: action.payload,
            };

        case actionTypes.SET_CURRENT_PRODUCT_CODE:
            return {
                ...state,
                productCode: action.payload,
            };

        case actionTypes.SET_IS_TIMER_END:
            return {
                ...state,
                isTimerEnd: action.payload,
            };

        case actionTypes.SET_IS_APPLE_PAY_AVAILABLE:
            return {
                ...state,
                isApplePayAvailable: action.payload,
            };

        case actionTypes.SET_IS_GOOGLE_PAY_AVAILABLE:
            return { ...state, isGooglePayAvailable: action.payload };

        case actionTypes.SET_PAYMENT_RETENO_ERROR:
            return {
                ...state,
                paymentFailToRetenoObj: action.payload,
            };

        case actionTypes.SET_IS_LOADING_BANK_CARD:
            return {
                ...state,
                isLoadingBankCard: action.payload,
            };

        case actionTypes.SET_IS_LOADING_PAY_PAL:
            return {
                ...state,
                isLoadingPayPal: action.payload,
            };

        case actionTypes.SET_IS_PAYMENT_SUCCESS:
            return {
                ...state,
                isPaymentSuccess: action.payload,
            };

        case actionTypes.SET_USER_INVOICE_INFO_RECEIVED:
            return {
                ...state,
                invoiceInfoReceived: action.payload,
            };

        case actionTypes.SET_PREV_PAYMENT_ATTEMPT:
            return {
                ...state,
                prevPaymentAttempt: action.payload,
            };

        default:
            return state;
    }
};

export default PaymentReducer;
