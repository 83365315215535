/* eslint-disable max-lines */
export const DEFAULT_TRIAL_PRICE = 30.99;
export const DEFAULT_TRIAL_OFFER_FULL_PRICE = 38.95;
export const DAYS_PER_YEAR = 365;
export const DAYS_PER_MONTH = 31;
export const DAYS_PER_WEEK = 7;
export const DAYS_PER_TWO_MONTH = 62;
export const DAYS_PER_THREE_MONTH = 90;
export const DAYS_PER_FOUR_MONTH = 121;
export const DAYS_PER_FIVE_MONTH = 152;
export const DAYS_PER_SIX_MONTH = 182;
export const DAYS_PER_SEVEN_MONTH = 212;
export const DAYS_PER_EIGHT_MONTH = 243;

export const PAYMENT_TYPE_SUBSCRIPTION = 'subscription';
export const PAYMENT_TYPE_LIFETIME = 'lifetime';

export const GOOGLE_PAY_OPTION = 'google_pay';
export const CREDIT_CARD_OPTION = 'Bank_Card';
export const PAYPAL_OPTION = 'PayPal';
export const APPLE_PAY_OPTION = 'apple_pay';

export const PAYMENT_EVENT_NAMES = {
    applebtn: APPLE_PAY_OPTION,
    googlebtn: GOOGLE_PAY_OPTION,
    form: CREDIT_CARD_OPTION,
};

export const PAYMENT_TYPES = {
    BANK_CARD: 1,
    GOOGLE_PAY: 2,
    APPLE_PAY: 3,
    PAYPAL: 4,
    ONE_CLICK_PAYMENT: 1,
};
export const PAYMENT_TYPES_NAME = {
    BANK_CARD: CREDIT_CARD_OPTION,
    PAYPAL: PAYPAL_OPTION,
    APPLE_PAY: APPLE_PAY_OPTION,
    GOOGLE_PAY: GOOGLE_PAY_OPTION,
    ONE_CLICK_PAYMENT: PAYPAL_OPTION,
};

export const PAYMENT_TYPES_CAPITALIZED = {
    BANK_CARD: 'BANK_CARD',
    PAYPAL: 'PAYPAL',
    APPLE_PAY: 'APPLE_PAY',
    GOOGLE_PAY: 'GOOGLE_PAY',
    ONE_CLICK_PAYMENT: 'ONE_CLICK_PAYMENT',
};

export const ONE_CLICK_OPTIONS = [
    PAYMENT_TYPES_CAPITALIZED.PAYPAL,
    PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
    PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
    PAYMENT_TYPES_CAPITALIZED.ONE_CLICK_PAYMENT,
];

export const PAYMENT_METHOD_NAME: { [key: number]: string } = {
    1: CREDIT_CARD_OPTION,
    2: GOOGLE_PAY_OPTION,
    3: APPLE_PAY_OPTION,
    4: PAYPAL_OPTION,
};

export const PAYMENT_DEFAULT_CURRENCY = {
    sign: '$',
    name: 'USD',
};

export const EURO = {
    sign: '€',
    name: 'EUR',
};

export const GBP = {
    sign: '£',
    name: 'GBP',
};

export const AUD = {
    sign: 'A$',
    name: 'AUD',
};

export const CAD = {
    sign: 'C$',
    name: 'CAD',
};

export const BRL = {
    sign: 'R$',
    name: 'BRL',
};

export const MXN = {
    sign: 'M$',
    name: 'MXN',
};

export const ARS = {
    sign: 'ARS',
    name: 'ARS',
};

export const CLP = {
    sign: 'CLP$',
    name: 'CLP',
};

export const COP = {
    sign: 'COL$',
    name: 'COP',
};

export const PEN = {
    sign: 'S/',
    name: 'PEN',
};

export const DEVICE_OS = {
    ANDROID: 'ANDROID',
    IOS: 'IOS',
    MAC_OS: 'MAC OS',
    DEFAULT: 'DEFAULT_DEVICE',
};

export const PAYMENT_FLOW_PERIODS = [
    DAYS_PER_MONTH,
    DAYS_PER_TWO_MONTH,
    DAYS_PER_THREE_MONTH,
    DAYS_PER_FOUR_MONTH,
    DAYS_PER_FIVE_MONTH,
    DAYS_PER_SIX_MONTH,
    DAYS_PER_SEVEN_MONTH,
    DAYS_PER_EIGHT_MONTH,
    DAYS_PER_YEAR,
    DAYS_PER_WEEK,
];

export const INTRODUCTORY_B_FLOW_PAYMENT_ID = 'payment_B1_checkout_Bravo1';
export const INTRODUCTORY_C_FLOW_PAYMENT_ID = 'payment_C1_checkout_Bravo1';

export const PRODUCT_CODES = {
    APP_FULL_ACCESS: 'app_full_access',
    APP_VIP_SUPPORT: 'vip_support',
    APP_GUIDE_WEIGHT_LOSS: 'weight_loss_gd',
    APP_GUIDE_FAT_BURNING: 'fat_burning_gd',
    APP_GUIDE_ALL: 'fat_burning_gd__weight_loss_gd',
    APP_GUIDE_WEIGHT_LOSS_SALE: 'weight_loss_gd_full_offer',
    APP_GUIDE_FAT_BURNING_SALE: 'fat_burning_gd_full_offer',
    APP_GUIDE_ALL_SALE: 'fat_burning_gd__weight_loss_gd_full_offer',
    APP_GUIDE_WEIGHT_LOSS_FULL: 'weight_loss_gd_full',
    APP_GUIDE_FAT_BURNING_FULL: 'fat_burning_gd_full',
    APP_GUIDE_ALL_FULL: 'fat_burning_gd__weight_loss_gd_full',
};

export const BANK_CARD_CUSTOM_BUTTON_ID = 'customSubmitButton';

// 2.01 - Invalid Data/Order not found
// 2.03 - Invalid Currency
// 2.05 - Order not Found
// 2.10 - Invalid 3DS flow on the merchant side
// 2.15 - SCA require 3D authentication
const SOLID_INVALID_DATA_CODES = ['2.01', '2.03', '2.05', '2.10', '2.15'];

// 3.05 - Call your bank
// 3.06 - Debit card not supported
// 3.09 - 3D-Secure authentication required
// 3.11 - Recurring payment cancelled
const SOLID_TRANSACTION_DECLINES_CODES = ['3.05', '3.06', '3.09', '3.11'];

// 5.02 - Invalid Card Token
// 5.03 - Application Error
// 5.04 - Merchant is not configured yet
// 5.05 - Merchant is not activated yet
const SOLID_PROCESSING_ERRORS_CODES = ['5.02', '5.03', '5.04', '5.05'];

// 7.01 - Card Token not found
// 7.02 - Google payment error
// 7.03 - Smart cascade decline
// 7.04 - 3DS cascade to 2D
// 7.05 - Apple online payment error
// 7.06 - Token generation error
// 7.07 - SCA engine error
const SOLID_DECLINED_PAYMENTS_CODES = ['7.01', '7.02', '7.03', '7.04', '7.05', '7.07'];

export const SOLID_ERROR_GROUPS_NAME = {
    SOLID_NOT_STANDARD_ERROR: 'SOLID_NOT_STANDARD_ERROR',
    SOLID_INVALID_DATA_CODES: 'SOLID_INVALID_DATA_CODES',
    SOLID_PROCESSING_ERRORS_CODES: 'SOLID_PROCESSING_ERRORS_CODES',
    SOLID_DECLINED_PAYMENTS_CODES: 'SOLID_DECLINED_PAYMENTS_CODES',
    SOLID_TRANSACTION_DECLINES_CODES: 'SOLID_TRANSACTION_DECLINES',
};

export const SOLID_ERROR_CODES = [
    { name: SOLID_ERROR_GROUPS_NAME.SOLID_INVALID_DATA_CODES, errorList: SOLID_INVALID_DATA_CODES },
    { name: SOLID_ERROR_GROUPS_NAME.SOLID_PROCESSING_ERRORS_CODES, errorList: SOLID_PROCESSING_ERRORS_CODES },
    { name: SOLID_ERROR_GROUPS_NAME.SOLID_DECLINED_PAYMENTS_CODES, errorList: SOLID_DECLINED_PAYMENTS_CODES },
    { name: SOLID_ERROR_GROUPS_NAME.SOLID_TRANSACTION_DECLINES_CODES, errorList: SOLID_TRANSACTION_DECLINES_CODES },
];

export const US_DISCLAIMER = 'Solidgate LLC, Nevada, USA';
export const EU_DISCLAIMER = 'WELLTIKA CONTENT PROVIDER DMCC, United Arab Emirates';

export const SOLID_TAGS = {
    SOLID_PAYMENT_METHOD: 'SOLID_PAYMENT_METHOD',
    SOLID_ERROR_REASON: 'reason',
    SOLID_ERROR_CODE: 'SOLID_ERROR_CODE',
    SOLID_ERROR_MESSAGE: 'SOLID_ERROR_MESSAGE',
    SOLID_ORDER_ID: 'SOLID_ORDER_ID',
};

export const SOLID_ERROR_CODE_MESSAGE_MAP = {
    '3.08': 'Do not honor',
};

export const SOLID_ENTITY_TO_PAYMENT_METHOD_NAME_MAP = {
    form: PAYMENT_TYPES_CAPITALIZED.BANK_CARD,
    applebtn: PAYMENT_TYPES_CAPITALIZED.APPLE_PAY,
    googlebtn: PAYMENT_TYPES_CAPITALIZED.GOOGLE_PAY,
};
export const SOLID_ERROR_CODE_NAMES = {
    INSUFFICIENT_FUNDS: '3.02',
    CALL_YOUR_BANK: '3.05',
    DEBIT_CARD_NOT_SUPPORTED: '3.06',
    DO_NOT_HONOR: '3.08',
    THREE_D_SECURE_AUTH_REQUIRED: '3.09',
    SUSPECTED_FRAUD: '3.10',
    RECURRING_PAYMENT_CANCELLED: '3.11',
    INVALID_CARD_TOKEN: '5.02',
    APPLICATION_ERROR: '5.03',
    MERCHANT_NOT_CONFIGURED: '5.04',
    MERCHANT_NOT_ACTIVATED: '5.05',
    CARD_TOKEN_NOT_FOUND: '7.01',
    GOOGLE_PAYMENT_ERROR: '7.02',
    SMART_CASCADE_DECLINE: '7.03',
    THREE_D_CASCADE_TO_TWO_D: '7.04',
    APPLE_ONLINE_PAYMENT_ERROR: '7.05',
    TOKEN_GENERATION_ERROR: '7.06',
    SCA_ENGINE_ERROR: '7.07',
    // TODO: recheck does it good idea
    // for testing purposes
    ANY_ERROR: 'anyErrorCode',
};

export const weekProductTitle = 'paymentFlow.paymentE.cardTitle.startChange';
export const monthProductTitle = 'paymentFlow.paymentE.cardTitle.results';
export const threeMothProductTitle = 'paymentFlow.paymentE.cardTitle.fitAndStrong';
export const longProductTitle = 'paymentFlow.paymentE.cardTitle.investInHealth';
export const LONG_CHECKBOX_DISCLAIMER = 'long';
export const SHORT_CHECKBOX_DISCLAIMER = 'short';
export const LITE_DISCLAIMER_COLOUR = 'lite';
